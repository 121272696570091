.input_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input_wrap input {
  outline: none;
  border: 1px solid var(--bg-third);
  background: var(--bg-primary);
  width: 100%;
  height: 50px;
  font-size: 17px;
  border-radius: 10px;
  padding-left: 10px;
  color: var(--color-primary);
}
.input_wrap input:focus {
  border-color: var(--blue-color);
}
.input_error_border {
  border-color: #b94a48 !important;
}
.input_wrap i {
  position: absolute;
  right: 5px;
  top: 15px;
  transform: scale(0.8);
}
.input_error {
  position: relative;
  padding: 15px 10px;
  background: #b94a48;
  width: 100%;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.error_arrow_top {
  border-top: 10px solid #b94a48;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -10px;
}
.error_arrow_bottom {
  border-bottom: 10px solid #b94a48;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
}
.input_error_desktop {
  position: absolute;
  left: -80.5rem;
  bottom: -px;
  z-index: 40;
}
.error_arrow_left {
  border-left: 10px solid #b94a48;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: -10px;
  top: 13px;
}
