.pictureBox {
  width: 700px !important;

  height: 700px;
}
.update_picture_wrap {
  padding: 10px 15px;
}
.update_picture_buttons {
  display: flex;
  gap: 10px;
}
.update_picture_buttons button {
  width: 100%;
}
.update_picture_buttons .light_blue_btn {
  color: var(--blue-color);
}
.update_img {
  width: 700px !important;
  height: 800px;
}
.update_image_desc {
  padding: 15px;
}
.textarea_blue {
  width: 100%;
  height: 85px;
  resize: none;
  border-radius: 7px;
  outline-offset: 5px;
  border-color: var(--bg-third);
  padding: 1.1rem;
  font-size: 16px;
  font-family: inherit;
  background: var(--bg-primary);
  color: var(--color-primary);
}
.textarea_blue:focus {
  outline-color: var(--blue-color);
}
.textarea_blue:hover {
  border-color: var(--color-secondary);
}
.update_center {
  position: relative;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crooper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
  width: 100%;
}
.crooper img {
  width: 100%;
  object-fit: contain;
}
.update_img .reactEasyCrop_CropAreaGrid {
  width: 300px !important;
  height: 300px !important;
  color: rgba(255, 255, 255, 0.5);
}
.slider {
  position: absolute;
  bottom: -20px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.slider input[type="range"] {
  -webkit-appearance: none;
  width: 55%;
  height: 4px;
  background: #ced0d4;
}
.slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 2px var(--shadow-1), 0 2px 2px 0 var(--shadow-2),
    inset 0 0 0 1px var(--shadow-inset);
  border: 1px solid var(--bg-third);
}
.slider_circle {
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.flex_up {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 2rem;
}
.flex_p_t {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  color: var(--color-secondary);
  font-size: 17px;
  border-bottom: 1px solid var(--bg-third);
}
.update_submit_wrap {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.blue_link {
  color: var(--blue-color);
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.update_submit_wrap button {
  width: 110px !important;
}
.old_pictures_wrap {
  padding: 15px;
  overflow-y: auto;
  height: 550px;
}
.old_pictures_wrap h4 {
  padding: 10px 0;
}
.old_pictures {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 10px;
}
.old_pictures img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}
.old_pictures img:hover {
  filter: brightness(110%);
}
.old_pictures:first-of-type {
  border-bottom: 1px solid var(--bg-third);
}
@media (max-width: 720px) {
  .postBox.pictureBox {
    width: 98% !important;
  }
}
@media (max-width: 589px) {
  .postBox .old_pictures {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .postBox .old_pictures img {
    width: 100%;
    height: 110px;
  }
}
@media (max-width: 430px) {
  .postBox .old_pictures {
    grid-template-columns: repeat(3, 1fr);
  }
  .box_header span {
    transform: translateX(-2rem);
  }
}
