

.login_wrap {
  height: 78vh;
  color: var(--color-primary);
}
.login_1 {
  width: 300px;
  margin: 0 auto;
}
.login_1 span {
  font-size: 20px;
}
.login_2 {
  text-align: center;
}
.login_2_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  box-shadow: 0 1px 2px var(--shadow-1);
  padding: 1rem;
  padding-bottom: 2rem;
  width: 350px;
  height: fit-content;
  margin: 1rem auto;
  user-select: none;
  border-radius: 10px;
}
.login_2_wrap button {
  width: 100%;
  height: 50px;
  font-size: 17px;
  font-weight: 700;
}
.forgot_password {
  color: var(--blue-color);
  font-size: 14px;
  cursor: pointer;
}
.forgot_password:hover {
  text-decoration: underline;
}
.sign_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
}
.open_signup {
  background: var(--green-color);
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  margin-top: 1rem;
}
.sign_extra {
  font-size: 15px;
}
/*-------login-----*/

/*-----Register---------*/
.register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #212121 ;
  .translucent-box {
    opacity: 0.5;
    box-shadow: 0 1px 2px var(--shadow-1);
  }
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 1rem;
  width: 350px;
  height: fit-content;
  color: gray;
}
.register_header {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  
}
.register_header i {
  position: absolute;
  right: 0;
  cursor: pointer;
  
}
.register_header span:first-of-type {
  font-weight: 700;
  font-size: 32px;
  
}
.register_header span:last-of-type {
  font-size: 15px;
  color:white;

  }
.register_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.reg_col {
  position: relative;
  align-self: flex-start;
  margin-bottom: 10px;
}
.reg_line_header {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  color:white;
  }
.reg_line_header i {
  margin-top: 3px;
}
.reg_grid {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  height: 35px;
  width: 100%;
}
.reg_grid select {
  width: 100%;
  font-size: 16px;
  color:white;
  background: var(--bg-primary);
  border:1px solid var(--bg-third);
  border-radius: 5px;
  cursor: pointer;
}
.reg_grid label {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color:white;
  background: var(--bg-primary);
  border:1px solid var(--bg-third);
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
}


.reg_infos {
  font-size: 11px;
  margin-top: 10px;
  color: gray;
}
.reg_infos span {
  color: var(--blue-color);
}
.reg_btn_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px 0;
}
/*-----Register---------*/

/*----Footer------*/
.login_footer {
  height: 22vh;
  background: var(--bg-primary);
  padding: 1.5rem;
}
.login_footer_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 3px 10px;
  font-size: 13px;
  color: var(--color-secondary);
  max-width: 350px;
  margin: 0 auto;
}
.login_footer_wrap a {
  display: flex;
  align-items: center;
}
.login_footer_wrap a:hover {
  text-decoration: underline;
}
.footer_square {
  background: var(--bg-secondary);
  height: 19px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-third);
}
.footer_square i {
  transform: scale(0.8);
  -webkit-filter: invert(40%);
}
.footer_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
  margin: 10px auto;
  max-width: 900px;
}
/*----Footer------*/

/*----dob and gender errors-----*/
.reg_grid .input_error {
  width: 300px;
  position: absolute;
  top: 70px;
}
.input_error_select_large {
  left: -97%;
  top: 15px !important;
}
/*----dob and gender errors-----*/

/*----error and success styles -------*/
.error_text {
  color: #b94a48;
}
.success_text {
  color: var(--green-color);
  text-align: center;
}
/*----error and success styles -------*/

/*----Media queries--------------*/
@media (min-width: 539px) {
  .login_footer_wrap {
    max-width: 520px;
  }
  .register {
    width: 400px;
  }
  /* .reg_line {
    flex-direction: row;
  } */
  .reg_grid select,
  .reg_grid label {
    width: 100%;
  }
  .reg_grid .input_error {
    width: 350px;
  }
}
@media (min-width: 650px) {
  .login_footer_wrap {
    max-width: 650px;
  }
}
@media (min-width: 850px) {
  .login_wrap {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }
  .login_1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 15vh;
  }
  .login_1 img {
    width: 300px;
    margin-left: -1.7rem;
  }
  .login_1 span {
    font-size: 28px;
  }
  .login_2_wrap {
    width: 400px;
  }
  .input_wrap {
    width: 100% !important;
  }
  .login_footer_wrap {
    max-width: 900px;
  }
}
/*----Media queries--------------*/
