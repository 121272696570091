
@keyframes star {
  to {
    zoom: 10%;
    opacity: 0;
  }
  from {
    zoom: 100%;
    opacity: 1;
  }
}

.star {
  animation-name: star;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-direction: alternate;
}

@keyframes star1 {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}

.star1 {
  animation-name: star1;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-direction: alternate;
}

@keyframes star2 {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.star2 {
  animation-name: star2;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-direction: alternate;
}
