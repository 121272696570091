.LocationBtn{
    animation: bounce 1s infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: translateX(10px);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}