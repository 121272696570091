/* .register_input_wrap {
  width: 100% !important;
} */
.register_input_wrap input {
  background: var(--bg-secondary);
}
/* .register_input_wrap .input_error {
  max-width: 300px;
} */
.register_input_wrap .error_arrow_bottom {
  transform: translateY(1px);
}
.error_arrow_right {
  border-right: 10px solid #b94a48;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  left: -10px;
  top: 14px;
}
/* @media (min-width: 539px) {
  .register_input_wrap .input_error {
    max-width: 100%;
  }
} */
