header {
  z-index: 9999999999999;
  color: var(--color-primary);
}
/*---Header left-----*/
.header_left {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 1rem;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--bg-forth);
  padding: 10px 32px 10px 10px;
  border-radius: 50px;
  cursor: text;
  color: white;
}
.search input {
  outline: none;
  border: none;
  background: transparent;
  font-size: 15px;
  font-family: inherit;
}
.search input::placeholder {
  transform: translateY(-1px);
}
/*---Header left-----*/


/*---Search menu-----*/
.search_area {
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background: var(--bg-primary);
  box-shadow: 0 12px 12px var(--shadow-1), inset 0 0 0 0 var(--shadow-inset);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.2s;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  min-height: 400px;
  max-height: 70vh;
  padding: 8px 16px 10px 30px;
}
.search_wrap {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search_area .search {
  padding: 10px 44px 10px 12px;
  width: 100%;
  gap: 4px;
}
.search_area .search input {
  padding-left: 4px;
  color: var(--color-primary);
}
.search_area .search svg {
  transform: translateY(1px);
}
.search_area .search input::placeholder {
  transform: translateY(-2px);
}
.search_area .search input:focus::placeholder {
  transform: translateY(-1px);
}
.search_history {
  width: 100%;
}
.search_history_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
}
.search_history_header span {
  font-weight: 600;
  font-size: 16px;
}
.search_history_header a {
  cursor: pointer;
  color: var(--blue-color);
}

/*---Search menu-----*/
/*---Search All Menu-----*/
.all_menu {
  position: absolute;
  right: -9rem;
  top: 45px;
  background: var(--bg-secondary);
  border-radius: 10px;
  height: 90vh;
  width: 575px;
  padding: 10px 1rem;
  box-shadow: -2px 1px 5px 2px var(--shadow-1);
  user-select: none;
}
.all_menu_header {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.all_menu_wrap {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 1rem;
  overflow-y: auto;
  height: 95%;
}

.all_left {
  background: var(--bg-primary);

  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.all_menu_search {
  background: var(--bg-secondary);

  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 50px;
}
.all_menu_search input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 15px;
}
.all_menu_group {
  margin-top: 10px;
  border-bottom: 1px solid #ced0d4;
}
.all_menu_group_header {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
}
.all_menu_group {
  width: 100%;
}
.all_menu_item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
}

.all_menu_item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
.all_menu_col {
  display: flex;
  flex-direction: column;
}
.all_menu_col span:first-of-type {
  font-size: 14px;
  font-weight: 500;
}
.all_menu_col span:last-of-type {
  font-size: 12px;
  color: #65676b;
  width: 95%;
}
.all_right {
  padding: 15px 10px;
  background: var(--bg-primary);
  margin-right: 1rem;
  border-radius: 10px;
  height: 550px;
  width: 170px;
  position: fixed;
  right: 2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.all_right_header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.all_right_item {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 2px;
}

.all_right_circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-third);
}
/*---Search All Menu-----*/

/*-------User menu----*/

.mmenu {
  padding: 0 0.3rem;
  position: absolute;
  top: 100%;
  right: 0;
  width: 360px;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}
.mmenu_header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.mmenu_header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.mmenu_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmenu_col span:first-of-type {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
}
.mmenu_col span:last-of-type {
  font-size: 14px;
}
.mmenu_main {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mmenu_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
  margin-top: 5px;
}
.mmenu_span1 {
  font-size: 14px !important;
}
.mmenu_span2 {
  font-size: 12px !important;
  color: var(--color-secondary);
}
.mmenu_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}
.rArrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.absolute_wrap {
  padding: 0 0.3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 360px;
  height: fit-content;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}
.absolute_wrap .mmenu_item {
  margin-top: 0;
}
.absolute_wrap_header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 24px;
}

.absolute_wrap label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.absolute_wrap label input {
  width: 20px;
  height: 20px;
}
.search_user_item i {
  position: absolute;
  right: 10px;
  transform: scale(0.6);
}

/*-------User menu----*/
.search_results {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_history {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_user_item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.search_user_item img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.search_user_item span {
  font-weight: 600;
  font-size: 14px;
}

/*---responsive----------*/
@media (max-width: 1295px) {
  .middle_icon {
    width: 90px;
  }
}
@media (max-width: 1130px) {
  .middle_icon {
    width: 70px;
  }
}
@media (max-width: 1040px) {
  

  .header_middle {
    transform: translateX(-15%);
  }
}
@media (max-width: 945px) {
  .header_middle {
    transform: translateX(-22%);
  }
}
@media (max-width: 850px) {
  .header_middle {
    transform: translateX(-30%);
  }
  .middle_icon:nth-child(5) {
    display: none;
  }
}
@media (max-width: 770px) {
  .header_middle {
    transform: translateX(-37%);
  }
  .middle_icon:nth-child(4) {
    display: none;
  }
}
@media (max-width: 720px) {
  .middle_icon:nth-child(3) {
    display: none;
  }
}
/*---responsive----------*/
@media (max-width: 620px) {
  .profile_link {
    display: none;
  }
  .all_menu {
    width: 485px;
  }
  .all_left {
    width: 250px;
  }
}
@media (max-width: 550px) {
  .middle_icon:nth-child(1) {
    display: none;
  }
  .header_middle {
    transform: translateX(-20%);
  }
}
@media (max-width: 505px) {
  .all_menu {
    width: 340px;
    right: -9rem;
  }
  .all_menu_wrap {
    grid-template-columns: 1fr;
  }
  .all_left {
    width: 100%;
  }
  .all_right {
    position: relative;
    width: 100%;
    padding-left: 3rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 400px) {
  .header_right .circle_icon:nth-child(3) {
    display: none;
  }
  .mmenu {
    width: 290px;
  }
  .all_menu {
    width: 310px;
    left: -10rem;
  }
  .search_area {
    width: 310px;
  }
}
/*---responsive----------*/

/*----Active header----*/

.active_header svg {
  fill: var(--blue-color);
}
.active_link {
  background: var(--light-blue-color);
  color: #121212;
}
/*----Active header----*/

@media (max-width: 767px) {
  .desktop-header {
    display: none; /* Hide on screens 767 pixels wide or smaller */
  }
}
